@import './../../../assets/stylesheets/component';

.computer {
  background-image: url('./computer.png');
  height: 74px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 74px;
}

.screen {
  background-image: url('./screen.gif');
  height: 40px;
  left: 7px;
  position: absolute;
  top: 8px;
  width: 60px;
}
