@import './../../../assets/stylesheets/component';

$rpf-color-footer-background: #edeef1;
$rpf-color-footer-grey: #666;
$rpf-color-footer-textarea-border: #ccc;
$rpf-color-footer-button-disabled-bg: #eee;

.c-project-footer {
  background-color: $rpf-color-footer-background;
  margin: 0 auto;
  padding: 32px 16px;
  text-align: center;

  p {
    color: $rpf-color-footer-grey;
    font-size: 14px;
    margin-bottom: 1em;

    &:last-child {
      margin: 0;
    }
  }
}

.c-project-footer__link,
.c-project-footer__link:hover,
.c-project-footer__link:visited {
  color: $rpf-color-footer-grey;
}

.c-project-footer__feedback-prompt,
.c-project-footer__feedback-submit {
  background-color: transparent;
  border-color: #464646;
  color: #464646;
  font-size: 16px;
  margin-block-end: 24px;
  transition: all 150ms ease-in-out;

  &:visited {
    color: var(--legacy-grey-mid);
  }

  &:hover:not(.disabled) {
    background-color: var(--rpf-white);
  }

  &[disabled],
  &:hover[disabled] {
    background-color: $rpf-color-footer-button-disabled-bg;
    border-color: $rpf-color-footer-button-disabled-bg;
    color: var(--legacy-grey-mid);
    cursor: default;
    pointer-events: none;
  }
}

.c-project-footer__feedback-form {
  margin: 0 auto 24px;
  max-width: 350px;
  text-align: center;
  width: 100%;
}

.c-project-footer__feedback-textarea {
  border: 2px solid $rpf-color-footer-textarea-border;
  border-radius: 5px;
  display: block;
  font-size: $rpi-font-size-base;
  height: 120px;
  margin-block-end: 16px;
  padding: 8px;
  text-align: left;
  width: 100%;

  &::placeholder {
    color: var(--legacy-grey-light);
  }

  &:focus {
    border-color: var(--legacy-grey-mid);
    outline-offset: 1px;
  }

  &[disabled] {
    border-color: $rpf-color-footer-textarea-border;
    color: var(--legacy-grey-light);
  }
}

.c-project-footer__feedback-thanks {
  @include rpi-typog('h1');
  font-weight: 700;
  margin-block-end: 16px;
  text-transform: uppercase;
}

.c-project-footer__feedback-form--hidden,
.c-project-footer__feedback-prompt--hidden,
.c-project-footer__feedback-thanks--hidden {
  display: none;
}

.c-project-footer-policy {
  column-gap: 30px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}
