@import './../../../assets/stylesheets/component';

$color-dropdown-border: $rpi-text;
$color-dropdown-text: $rpi-text;
$color-dropdown-focus: $rpi-blue;

.c-dropdown {
  appearance: none;
  background-color: transparent;
  background-image: url('./images/dropdown-arrow.svg');
  background-position: calc(100% - 0.75rem) center;
  background-repeat: no-repeat;
  border-color: $color-dropdown-border;
  border-radius: var(--spacing-1);
  border-style: solid;
  border-width: 2px;
  color: $color-dropdown-text;
  cursor: pointer;
  display: block;
  font-size: $rpi-font-size-base;
  font-weight: var(--font-weight-regular);
  height: var(--spacing-6);
  padding: 0 calc(var(--spacing-1) * 1.5);
  width: 100%;

  &::-ms-expand {
    display: none;
  }

  &:focus,
  &:focus-visible {
    outline-color: $color-dropdown-focus;
    outline-style: solid;
    outline-width: 3px;
  }
}

.c-i18n-root--rtl {
  .c-dropdown {
    background-position: 0.75rem center;
  }
}
