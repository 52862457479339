@mixin rpi-typog($element-type: null) {
  @include rpi-breakpoints using ($rpi-screensize) {
    font-size: rpi-font-size($element-type, $rpi-screensize);
    line-height: rpi-line-height($element-type, $rpi-screensize);
  }
}

@mixin rpi-type($font-size: null, $line-height: null) {
  font-size: $font-size;
  line-height: $line-height;
}
