@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import 'node_modules/raspberry-pi-bits/src/styles/react-main';
@import 'node_modules/raspberry-pi-bits/src/components/PrintPageBreak/PrintPageBreak';

body {
  background-color: white;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  min-width: 275px;
  width: auto !important;
  width: 275px;
}

#root {
  container-name: root;
  container-type: normal;
  min-height: 100dvh;
}
