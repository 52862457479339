$spacing-multiplier: 1.6;

$space-0-25: calc(0.25rem * $spacing-multiplier);
$space-0-5: calc(0.5rem * $spacing-multiplier);
$space-0-75: calc(0.75rem * $spacing-multiplier);
$space-1: calc(1rem * $spacing-multiplier);
$space-1-5: calc(1.5rem * $spacing-multiplier);
$space-2: calc(2rem * $spacing-multiplier);
$space-2-5: calc(2.5rem * $spacing-multiplier);
$space-3: calc(3rem * $spacing-multiplier);
$space-3-5: calc(3.5rem * $spacing-multiplier);
$space-4: calc(4rem * $spacing-multiplier);
$space-4-5: calc(4.5rem * $spacing-multiplier);
$space-5: calc(5rem * $spacing-multiplier);
$space-5-5: calc(5.5rem * $spacing-multiplier);
$space-6: calc(6rem * $spacing-multiplier);
$space-6-5: calc(6.5rem * $spacing-multiplier);
$space-7: calc(7rem * $spacing-multiplier);
$space-7-5: calc(7.5rem * $spacing-multiplier);
$space-8: calc(8rem * $spacing-multiplier);
$space-8-5: calc(8.5rem * $spacing-multiplier);
$space-9: calc(9rem * $spacing-multiplier);
$space-9-5: calc(9.5rem * $spacing-multiplier);
$space-10: calc(10rem * $spacing-multiplier);
