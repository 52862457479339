.shadow {
  align-items: center;
  background-color: #FF1493;
  display:flex;
  height: 50vh;
  justify-content: center;
  margin-bottom: 2rem;
  width: 50vw;
}

.rpf-shadow-bottom {
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, .4);
}

.rpf-shadow-centre {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .08);
}

.rpf-shadow-top {
  box-shadow: 0px -12px 24px 0px rgba(0, 0, 0, .04);

}
