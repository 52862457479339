:root, :host {
  --rpf-text-primary: hsla(0, 0%, 13%, 1);
  --rpf-text-primary-darkmode: var(--rpf-white);
  --rpf-text-secondary: hsla(200, 9%, 33%, 1);
  --rpf-text-secondary-darkmode: hsla(0, 0%, 80%, 1);
}

:root, :host {
  --rpf-red-100: hsla(4, 89%, 96%, 1);
  --rpf-red-200: hsla(1, 87%, 91%, 1);
  --rpf-red-400: hsla(1, 87%, 82%, 1);
  --rpf-red-600: hsla(1, 87%, 72%, 1);
  --rpf-red-800: hsla(1, 87%, 63%, 1);
  --rpf-red-900: hsla(1, 52%, 50%, 1);
  --rpf-green-100: hsla(125, 41%, 95%, 1);
  --rpf-green-200: hsla(122, 39%, 87%, 1);
  --rpf-green-400: hsla(123, 40%, 74%, 1);
  --rpf-green-600: hsla(123, 40%, 61%, 1);
  --rpf-green-800: hsla(123, 43%, 48%, 1);
  --rpf-green-900: hsla(123, 43%, 34%, 1);
  --rpf-teal-100: hsla(176, 55%, 94%, 1);
  --rpf-teal-200: hsla(174, 55%, 85%, 1);
  --rpf-teal-400: hsla(174, 55%, 70%, 1);
  --rpf-teal-600: hsla(174, 56%, 56%, 1);
  --rpf-teal-800: hsla(174, 81%, 41%, 1);
  --rpf-teal-900: hsla(174, 81%, 27%, 1);
  --rpf-orange-100: hsla(31, 100%, 95%, 1);
  --rpf-orange-200: hsla(32, 100%, 88%, 1);
  --rpf-orange-400: hsla(31, 100%, 77%, 1);
  --rpf-orange-600: hsla(31, 99%, 65%, 1);
  --rpf-orange-800: hsla(31, 99%, 54%, 1);
  --rpf-orange-900: hsla(31, 85%, 35%, 1);
  --rpf-yellow-100: hsla(51, 100%, 95%, 1);
  --rpf-yellow-200: hsla(52, 100%, 87%, 1);
  --rpf-yellow-400: hsla(51, 100%, 75%, 1);
  --rpf-yellow-600: hsla(52, 100%, 63%, 1);
  --rpf-yellow-800: hsla(52, 100%, 50%, 1);
  --rpf-yellow-900: hsla(52, 100%, 26%, 1);
  --rpf-purple-100: hsla(282, 36%, 95%, 1);
  --rpf-purple-200: hsla(279, 38%, 87%, 1);
  --rpf-purple-400: hsla(279, 38%, 73%, 1);
  --rpf-purple-600: hsla(279, 37%, 60%, 1);
  --rpf-purple-800: hsla(279, 43%, 47%, 1);
  --rpf-purple-900: hsla(280, 43%, 37%, 1);
  --rpf-navy-100: hsla(235, 38%, 94%, 1);
  --rpf-navy-200: hsla(234, 38%, 84%, 1);
  --rpf-navy-400: hsla(235, 38%, 69%, 1);
  --rpf-navy-600: hsla(235, 38%, 53%, 1);
  --rpf-navy-800: hsla(235, 63%, 37%, 1);
  --rpf-navy-900: hsla(234, 63%, 30%, 1);
  --rpf-blue-100: hsla(203, 86%, 95%, 1);
  --rpf-blue-200: hsla(204, 88%, 87%, 1);
  --rpf-blue-400: hsla(204, 88%, 74%, 1);
  --rpf-blue-600: hsla(204, 89%, 60%, 1);
  --rpf-blue-800: hsla(204, 100%, 47%, 1);
  --rpf-blue-900: hsla(204, 99%, 37%, 1);
}

:root, :host {
  --rpf-white: hsla(0, 0%, 100%, 1);
  --rpf-off-white: hsla(225, 25%, 97%, 1);
  --rpf-grey-50: hsla(210, 8%, 95%, 1);
  --rpf-grey-100: hsla(228, 10%, 90%, 1);
  --rpf-grey-150: hsla(223, 9%, 85%, 1);
  --rpf-grey-200: hsla(228, 10%, 80%, 1);
  --rpf-grey-300: hsla(229, 9%, 75%, 1);
  --rpf-grey-400: hsla(229, 8%, 61%, 1);
  --rpf-grey-500: hsla(229, 9%, 42%, 1);
  --rpf-grey-600: hsla(228, 9%, 32%, 1);
  --rpf-grey-700: hsla(228, 9%, 22%, 1);
  --rpf-grey-800: hsla(232, 9%, 18%, 1);
  --rpf-grey-850: hsla(228, 9%, 15%, 1);
  --rpf-grey-900: hsla(228, 9%, 10%, 1);
  --rpf-grey-950: hsla(228, 8%, 5%, 1);
  --rpf-black: hsla(0, 0%, 0%, 1);
}

body {
  --cc-green: hsla(129, 47%, 48%, 1);
  --cc-blue: hsla(238, 39%, 45%, 1);
  --cc-raspberry: var(--rpf-brand-raspberry);
  --cc-yellow: hsla(53, 100%, 50%, 1);
  --cc-orange: hsla(13, 87%, 61%, 1);
  --cc-pink: hsla(332, 82%, 66%, 1);
  --cc-cyan: hsla(199, 74%, 54%, 1);
  --cc-off-white: hsla(36, 38%, 95%, 1);
  --cc-burgundy: hsla(332, 48%, 26%, 1);
  --cc-dark-green: hsla(166, 68%, 7%, 1);
  --cc-grey: hsla(237, 17%, 26%, 1);
  --cc-light-yellow: hsla(56, 88%, 78%, 1);
  --cc-light-orange: hsla(13, 89%, 89%, 1);
  --cc-light-pink: hsla(333, 100%, 91%, 1);
  --cc-light-cyan: hsla(199, 100%, 87%, 1);
  --cc-light-green: var(--cc-green-200);
  --cc-light-pink: hsla(333, 100%, 91%, 1);
  --cc-light-lilac: hsla(239, 87%, 88%, 1);
  --cc-green-700: hsla(129, 47%, 29%, 1);
  --cc-green-600: hsla(129, 47%, 39%, 1);
  --cc-green-500: var(--cc-green);
  --cc-green-400: hsla(129, 45%, 59%, 1);
  --cc-green-300: hsla(129, 44%, 69%, 1);
  --cc-green-200: hsla(129, 58%, 83%, 1);
  --cc-green-100: hsla(130, 46%, 95%, 1);
  --cc-green-50: hsla(130, 43%, 97%, 1);
  --cc-blue: #46499e;
  --cc-pale-orange: hsla(13, 100%, 97%, 1);
  --cc-pale-cyan: hsla(198, 91%, 96%, 1);
  --cc-pale-green: hsla(127, 100%, 96%, 1);
  --cc-pale-pink: hsla(332, 100%, 97%, 1);
  --cc-pale-lilac: hsla(240, 100%, 96%, 1);
  --rpf-white: #ffffff;
  --rpf-off-white: #f5f6f9;
  --rpf-black: #000000;
  --rpf-red: #c2423f;
  --ccw-blue: #2253e7;
  --legacy-grey-mid: #999999;
  --legacy-grey-light: #b8b8b8;
  --legacy-grey-x-light: #f5f6fa;
  --legacy-ui-grey-dark: hsl(0, 0%, 27%);
  --legacy-ui-yellow: hsl(57, 95%, 74%);
  --legacy-blue: hsl(194, 53%, 51%);
  --legacy-blue-light: hsl(194, 52%, 95%);
  --legacy-navy: hsl(207, 100%, 25%);
  --legacy-orange-light: hsl(25, 90%, 96%);
  --legacy-red: hsl(1, 87%, 63%);
  --legacy-yellow: hsl(38, 100%, 58%);
}

/**
 * The base value the scale starts at.
 *
 * Number
 */
/**
 * The ratio the scale is built on
 *
 * Unitless Number
 */
/**
 * Length of scale ( right part of the decimal
 * point ) ms will be rounded to.
 *
 * Unitless Number
 */
/* stylelint-disable */
/**
 * Based on a scale with a base of 1rem and a ratio of 1.2
 * https://www.modularscale.com/?1&rem&1.2
 * Each step up:   (previous-step) * 1.2
 * Each step down: (previous-step) / 1.2
 */
:root,
:host {
  --font-size-base: 1.6rem;
  --font-size-ratio: 1.2;
  --font-size-u-8: 6.879707136rem;
  --font-size-u-7: 5.73308928rem;
  --font-size-u-6: 4.7775744rem;
  --font-size-u-5: 3.981312rem;
  --font-size-u-4: 3.31776rem;
  --font-size-u-3: 2.7648rem;
  --font-size-u-2: 2.304rem;
  --font-size-u-1: 1.92rem;
  --font-size-d-1: 1.3333333333rem;
  --font-size-d-2: 1.1111111111rem;
  --font-size-d-3: 0.9259259259rem;
  --font-size-root-clamp-ideal: 3vmin;
  --font-size-root-clamp-min: 1em;
  --font-size-root-clamp-max: 1.25em;
  --font-size-root-clamp-fallback: 1em;
}

:root,
:host {
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
}

:root,
:host {
  --spacing-multiplier: 0.8;
  --spacing-0: 0;
  --spacing-1: calc(1rem * var(--spacing-multiplier));
  --spacing-2: calc(2rem * var(--spacing-multiplier));
  --spacing-3: calc(3rem * var(--spacing-multiplier));
  --spacing-4: calc(4rem * var(--spacing-multiplier));
  --spacing-5: calc(5rem * var(--spacing-multiplier));
  --spacing-6: calc(6rem * var(--spacing-multiplier));
  --spacing-7: calc(7rem * var(--spacing-multiplier));
  --spacing-8: calc(8rem * var(--spacing-multiplier));
  --spacing-9: calc(9rem * var(--spacing-multiplier));
  --spacing-10: calc(10rem * var(--spacing-multiplier));
}

.u-visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.shadow {
  align-items: center;
  background-color: #FF1493;
  display: flex;
  height: 50vh;
  justify-content: center;
  margin-bottom: 2rem;
  width: 50vw;
}

.rpf-shadow-bottom {
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.4);
}

.rpf-shadow-centre {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}

.rpf-shadow-top {
  box-shadow: 0px -12px 24px 0px rgba(0, 0, 0, 0.04);
}

:root,
:host {
  --rpi-transparent: rgba(0, 0, 0, 0);
  --rpi-white: #fff;
  --rpi-black: #000;
  --rpi-red: #f3524f;
  --rpi-red-shade-20: #c2423f;
  --rpi-red-tint-25: #f67d7b;
  --rpi-red-tint-50: #f9a9a7;
  --rpi-red-tint-75: #fcd4d3;
  --rpi-red-tint-90: #feeeed;
  --rpi-blue: #008ff0;
  --rpi-blue-shade-20: #0072c0;
  --rpi-blue-tint-25: #40abf4;
  --rpi-blue-tint-50: #80c7f8;
  --rpi-blue-tint-75: #bfe3fb;
  --rpi-blue-tint-90: #e6f4fe;
  --rpi-navy: #232e9b;
  --rpi-navy-shade-20: #1c257c;
  --rpi-navy-tint-25: #5a62b4;
  --rpi-navy-tint-50: #9197cd;
  --rpi-navy-tint-75: #c8cbe6;
  --rpi-navy-tint-90: #e9eaf5;
  --rpi-orange: #fe8e15;
  --rpi-orange-shade-20: #af620e;
  --rpi-orange-tint-25: #feaa50;
  --rpi-orange-tint-50: #ffc78a;
  --rpi-orange-tint-75: #ffe3c5;
  --rpi-orange-tint-90: #fff4e8;
  --rpi-yellow: #ffdc00;
  --rpi-yellow-shade-20: #877500;
  --rpi-yellow-tint-25: #ffe540;
  --rpi-yellow-tint-50: #ffee80;
  --rpi-yellow-tint-75: #fff6bf;
  --rpi-yellow-tint-90: #fffce6;
  --rpi-purple: #8744aa;
  --rpi-purple-shade-20: #6c3688;
  --rpi-purple-tint-25: #a573bf;
  --rpi-purple-tint-50: #c3a2d5;
  --rpi-purple-tint-75: #e1d0ea;
  --rpi-purple-tint-90: #f3ecf7;
  --rpi-green: #46af4b;
  --rpi-green-shade-20: #358539;
  --rpi-green-tint-25: #74c378;
  --rpi-green-tint-50: #a3d7a5;
  --rpi-green-tint-75: #d1ebd2;
  --rpi-green-tint-90: #edf7ed;
  --rpi-teal: #14bcac;
  --rpi-teal-shade-20: #0e857a;
  --rpi-teal-tint-25: #4fcdc1;
  --rpi-teal-tint-50: #8aded6;
  --rpi-teal-tint-75: #c4eeea;
  --rpi-teal-tint-90: #e8f8f7;
  --rpi-black: #000;
  --rpi-white: #fff;
  --rpi-grey-5: #f2f2f2;
  --rpi-grey-15: #d9d9d9;
  --rpi-grey-40: #999999;
  --rpi-grey-70: #4d4d4d;
  --rpi-off-white: #f5f6f9;
  --rpi-text: #212121;
  --rpi-black-alpha-10: $rpi-black-alpha-10;
  --rpi-black-alpha-15: $rpi-black-alpha-15;
  --rpi-black-alpha-25: $rpi-black-alpha-25;
  --rpi-black-alpha-30: $rpi-black-alpha-30;
  --rpi-black-alpha-40: $rpi-black-alpha-40;
  --rpi-brand-raspberry: #cd2355;
  --rpi-brand-leaf: #46af4b;
  --rpi-brand-black: #1d1d1b;
}

/* stylelint-disable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:export {
  red-rpi-red: #f3524f;
  red-rpi-red-shade-20: #c2423f;
  red-rpi-red-tint-25: #f67d7b;
  red-rpi-red-tint-50: #f9a9a7;
  red-rpi-red-tint-75: #fcd4d3;
  red-rpi-red-tint-90: #feeeed;
  blue-rpi-blue: #008ff0;
  blue-rpi-blue-shade-20: #0072c0;
  blue-rpi-blue-tint-25: #40abf4;
  blue-rpi-blue-tint-50: #80c7f8;
  blue-rpi-blue-tint-75: #bfe3fb;
  blue-rpi-blue-tint-90: #e6f4fe;
  navy-rpi-navy: #232e9b;
  navy-rpi-navy-shade-20: #1c257c;
  navy-rpi-navy-tint-25: #5a62b4;
  navy-rpi-navy-tint-50: #9197cd;
  navy-rpi-navy-tint-75: #c8cbe6;
  navy-rpi-navy-tint-90: #e9eaf5;
  orange-rpi-orange: #fe8e15;
  orange-rpi-orange-shade-20: #af620e;
  orange-rpi-orange-tint-25: #feaa50;
  orange-rpi-orange-tint-50: #ffc78a;
  orange-rpi-orange-tint-75: #ffe3c5;
  orange-rpi-orange-tint-90: #fff4e8;
  yellow-rpi-yellow: #ffdc00;
  yellow-rpi-yellow-shade-20: #877500;
  yellow-rpi-yellow-tint-25: #ffe540;
  yellow-rpi-yellow-tint-50: #ffee80;
  yellow-rpi-yellow-tint-75: #fff6bf;
  yellow-rpi-yellow-tint-90: #fffce6;
  purple-rpi-purple: #8744aa;
  purple-rpi-purple-shade-20: #6c3688;
  purple-rpi-purple-tint-25: #a573bf;
  purple-rpi-purple-tint-50: #c3a2d5;
  purple-rpi-purple-tint-75: #e1d0ea;
  purple-rpi-purple-tint-90: #f3ecf7;
  violet-rpi-violet: #8744aa;
  violet-rpi-violet-shade-20: #6c3688;
  violet-rpi-violet-tint-25: #a573bf;
  violet-rpi-violet-tint-50: #c3a2d5;
  violet-rpi-violet-tint-75: #e1d0ea;
  violet-rpi-violet-tint-90: #f3ecf7;
  green-rpi-green: #46af4b;
  green-rpi-green-shade-20: #358539;
  green-rpi-green-tint-25: #74c378;
  green-rpi-green-tint-50: #a3d7a5;
  green-rpi-green-tint-75: #d1ebd2;
  green-rpi-green-tint-90: #edf7ed;
  teal-rpi-teal: #14bcac;
  teal-rpi-teal-shade-20: #0e857a;
  teal-rpi-teal-tint-25: #4fcdc1;
  teal-rpi-teal-tint-50: #8aded6;
  teal-rpi-teal-tint-75: #c4eeea;
  teal-rpi-teal-tint-90: #e8f8f7;
  grey-rpi-transparent: rgba(0, 0, 0, 0);
  grey-rpi-black: #000;
  grey-rpi-grey-15: #d9d9d9;
  grey-rpi-grey-40: #999999;
  grey-rpi-grey-5: #f2f2f2;
  grey-rpi-grey-70: #4d4d4d;
  grey-rpi-off-white: #f5f6f9;
  grey-rpi-text: #212121;
  grey-rpi-white: #fff;
  grey-rpi-black-alpha-10: rgba(0, 0, 0, 0.1);
  grey-rpi-black-alpha-15: rgba(0, 0, 0, 0.15);
  grey-rpi-black-alpha-25: rgba(0, 0, 0, 0.25);
  grey-rpi-black-alpha-30: rgba(0, 0, 0, 0.3);
  grey-rpi-black-alpha-40: rgba(0, 0, 0, 0.4);
  brand-rpi-brand-black: #1d1d1b;
  brand-rpi-brand-leaf: #46af4b;
  brand-rpi-brand-raspberry: #cd2355;
}

/* stylelint-enable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:root,
:host {
  --rpi-transparent: rgba(0, 0, 0, 0);
  --rpi-white: #fff;
  --rpi-black: #000;
  --rpi-red: #f3524f;
  --rpi-red-shade-20: #c2423f;
  --rpi-red-tint-25: #f67d7b;
  --rpi-red-tint-50: #f9a9a7;
  --rpi-red-tint-75: #fcd4d3;
  --rpi-red-tint-90: #feeeed;
  --rpi-blue: #008ff0;
  --rpi-blue-shade-20: #0072c0;
  --rpi-blue-tint-25: #40abf4;
  --rpi-blue-tint-50: #80c7f8;
  --rpi-blue-tint-75: #bfe3fb;
  --rpi-blue-tint-90: #e6f4fe;
  --rpi-navy: #232e9b;
  --rpi-navy-shade-20: #1c257c;
  --rpi-navy-tint-25: #5a62b4;
  --rpi-navy-tint-50: #9197cd;
  --rpi-navy-tint-75: #c8cbe6;
  --rpi-navy-tint-90: #e9eaf5;
  --rpi-orange: #fe8e15;
  --rpi-orange-shade-20: #af620e;
  --rpi-orange-tint-25: #feaa50;
  --rpi-orange-tint-50: #ffc78a;
  --rpi-orange-tint-75: #ffe3c5;
  --rpi-orange-tint-90: #fff4e8;
  --rpi-yellow: #ffdc00;
  --rpi-yellow-shade-20: #877500;
  --rpi-yellow-tint-25: #ffe540;
  --rpi-yellow-tint-50: #ffee80;
  --rpi-yellow-tint-75: #fff6bf;
  --rpi-yellow-tint-90: #fffce6;
  --rpi-purple: #8744aa;
  --rpi-purple-shade-20: #6c3688;
  --rpi-purple-tint-25: #a573bf;
  --rpi-purple-tint-50: #c3a2d5;
  --rpi-purple-tint-75: #e1d0ea;
  --rpi-purple-tint-90: #f3ecf7;
  --rpi-green: #46af4b;
  --rpi-green-shade-20: #358539;
  --rpi-green-tint-25: #74c378;
  --rpi-green-tint-50: #a3d7a5;
  --rpi-green-tint-75: #d1ebd2;
  --rpi-green-tint-90: #edf7ed;
  --rpi-teal: #14bcac;
  --rpi-teal-shade-20: #0e857a;
  --rpi-teal-tint-25: #4fcdc1;
  --rpi-teal-tint-50: #8aded6;
  --rpi-teal-tint-75: #c4eeea;
  --rpi-teal-tint-90: #e8f8f7;
  --rpi-black: #000;
  --rpi-white: #fff;
  --rpi-grey-5: #f2f2f2;
  --rpi-grey-15: #d9d9d9;
  --rpi-grey-40: #999999;
  --rpi-grey-70: #4d4d4d;
  --rpi-off-white: #f5f6f9;
  --rpi-text: #212121;
  --rpi-black-alpha-10: $rpi-black-alpha-10;
  --rpi-black-alpha-15: $rpi-black-alpha-15;
  --rpi-black-alpha-25: $rpi-black-alpha-25;
  --rpi-black-alpha-30: $rpi-black-alpha-30;
  --rpi-black-alpha-40: $rpi-black-alpha-40;
  --rpi-brand-raspberry: #cd2355;
  --rpi-brand-leaf: #46af4b;
  --rpi-brand-black: #1d1d1b;
}

/* stylelint-disable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:export {
  red-rpi-red: #f3524f;
  red-rpi-red-shade-20: #c2423f;
  red-rpi-red-tint-25: #f67d7b;
  red-rpi-red-tint-50: #f9a9a7;
  red-rpi-red-tint-75: #fcd4d3;
  red-rpi-red-tint-90: #feeeed;
  blue-rpi-blue: #008ff0;
  blue-rpi-blue-shade-20: #0072c0;
  blue-rpi-blue-tint-25: #40abf4;
  blue-rpi-blue-tint-50: #80c7f8;
  blue-rpi-blue-tint-75: #bfe3fb;
  blue-rpi-blue-tint-90: #e6f4fe;
  navy-rpi-navy: #232e9b;
  navy-rpi-navy-shade-20: #1c257c;
  navy-rpi-navy-tint-25: #5a62b4;
  navy-rpi-navy-tint-50: #9197cd;
  navy-rpi-navy-tint-75: #c8cbe6;
  navy-rpi-navy-tint-90: #e9eaf5;
  orange-rpi-orange: #fe8e15;
  orange-rpi-orange-shade-20: #af620e;
  orange-rpi-orange-tint-25: #feaa50;
  orange-rpi-orange-tint-50: #ffc78a;
  orange-rpi-orange-tint-75: #ffe3c5;
  orange-rpi-orange-tint-90: #fff4e8;
  yellow-rpi-yellow: #ffdc00;
  yellow-rpi-yellow-shade-20: #877500;
  yellow-rpi-yellow-tint-25: #ffe540;
  yellow-rpi-yellow-tint-50: #ffee80;
  yellow-rpi-yellow-tint-75: #fff6bf;
  yellow-rpi-yellow-tint-90: #fffce6;
  purple-rpi-purple: #8744aa;
  purple-rpi-purple-shade-20: #6c3688;
  purple-rpi-purple-tint-25: #a573bf;
  purple-rpi-purple-tint-50: #c3a2d5;
  purple-rpi-purple-tint-75: #e1d0ea;
  purple-rpi-purple-tint-90: #f3ecf7;
  violet-rpi-violet: #8744aa;
  violet-rpi-violet-shade-20: #6c3688;
  violet-rpi-violet-tint-25: #a573bf;
  violet-rpi-violet-tint-50: #c3a2d5;
  violet-rpi-violet-tint-75: #e1d0ea;
  violet-rpi-violet-tint-90: #f3ecf7;
  green-rpi-green: #46af4b;
  green-rpi-green-shade-20: #358539;
  green-rpi-green-tint-25: #74c378;
  green-rpi-green-tint-50: #a3d7a5;
  green-rpi-green-tint-75: #d1ebd2;
  green-rpi-green-tint-90: #edf7ed;
  teal-rpi-teal: #14bcac;
  teal-rpi-teal-shade-20: #0e857a;
  teal-rpi-teal-tint-25: #4fcdc1;
  teal-rpi-teal-tint-50: #8aded6;
  teal-rpi-teal-tint-75: #c4eeea;
  teal-rpi-teal-tint-90: #e8f8f7;
  grey-rpi-transparent: rgba(0, 0, 0, 0);
  grey-rpi-black: #000;
  grey-rpi-grey-15: #d9d9d9;
  grey-rpi-grey-40: #999999;
  grey-rpi-grey-5: #f2f2f2;
  grey-rpi-grey-70: #4d4d4d;
  grey-rpi-off-white: #f5f6f9;
  grey-rpi-text: #212121;
  grey-rpi-white: #fff;
  grey-rpi-black-alpha-10: rgba(0, 0, 0, 0.1);
  grey-rpi-black-alpha-15: rgba(0, 0, 0, 0.15);
  grey-rpi-black-alpha-25: rgba(0, 0, 0, 0.25);
  grey-rpi-black-alpha-30: rgba(0, 0, 0, 0.3);
  grey-rpi-black-alpha-40: rgba(0, 0, 0, 0.4);
  brand-rpi-brand-black: #1d1d1b;
  brand-rpi-brand-leaf: #46af4b;
  brand-rpi-brand-raspberry: #cd2355;
}

/* stylelint-enable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:root,
:host {
  --theme-100: var(--rpf-teal-100);
  --theme-200: var(--rpf-teal-200);
  --theme-300: var(--rpf-teal-800);
  --theme-400: var(--rpf-teal-900);
  --rpf-squiggle-color: var(--theme-300);
}

.c-error {
  background-color: var(--rpf-text-primary);
  min-height: calc(100vh - 40px);
  position: relative;
}

.c-error__wrapper {
  color: var(--rpf-white);
  left: 50%;
  max-width: 400px;
  min-width: 320px;
  padding: 0 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) {
  .c-error__wrapper {
    margin: 0 auto;
    min-width: 630px;
    padding: 0;
    text-align: left;
  }
}
@media screen and (min-width: 1000px) {
  .c-error__wrapper {
    min-width: 680px;
  }
}

.c-error__image {
  background-image: url("./images/computer.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 170px 146px;
  display: block;
  height: 146px;
  margin-bottom: 48px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-error__image {
    background-size: 210px 180px;
    float: left;
    height: 180px;
    margin: 16px 0 0;
    width: 210px;
  }
}
@media screen and (min-width: 1000px) {
  .c-error__image {
    margin: 24px 0 0;
  }
}

.c-error__screen {
  background: #53ce63;
  display: block;
  height: 75px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 16px;
  transform: translateX(-50%);
  width: 140px;
}
@media screen and (min-width: 768px) {
  .c-error__screen {
    height: 93px;
    top: 19px;
    width: 172px;
  }
}

.c-error__dialog {
  background-image: url("./images/dialog.svg");
  display: inline-block;
  height: 25px;
  position: absolute;
  width: 45px;
}

@media screen and (min-width: 768px) {
  .c-error__body {
    float: right;
    width: 330px;
  }
}
@media screen and (min-width: 1000px) {
  .c-error__body {
    width: 380px;
  }
}

.c-error__title {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 16px;
}

.c-error__button {
  background-color: var(--legacy-ui-yellow);
  border-radius: 5px;
  color: var(--rpf-text-primary);
  font-weight: 500;
  padding: 8px 32px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}
.c-error__button:visited {
  color: var(--rpf-text-primary);
}
.c-error__button:hover {
  background-color: var(--rpf-white);
  color: var(--rpf-text-primary);
}

.display-false {
  display: none;
}

