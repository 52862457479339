:root, :host {
  --rpf-text-primary: hsla(0, 0%, 13%, 1);
  --rpf-text-primary-darkmode: var(--rpf-white);
  --rpf-text-secondary: hsla(200, 9%, 33%, 1);
  --rpf-text-secondary-darkmode: hsla(0, 0%, 80%, 1);
}

:root, :host {
  --rpf-red-100: hsla(4, 89%, 96%, 1);
  --rpf-red-200: hsla(1, 87%, 91%, 1);
  --rpf-red-400: hsla(1, 87%, 82%, 1);
  --rpf-red-600: hsla(1, 87%, 72%, 1);
  --rpf-red-800: hsla(1, 87%, 63%, 1);
  --rpf-red-900: hsla(1, 52%, 50%, 1);
  --rpf-green-100: hsla(125, 41%, 95%, 1);
  --rpf-green-200: hsla(122, 39%, 87%, 1);
  --rpf-green-400: hsla(123, 40%, 74%, 1);
  --rpf-green-600: hsla(123, 40%, 61%, 1);
  --rpf-green-800: hsla(123, 43%, 48%, 1);
  --rpf-green-900: hsla(123, 43%, 34%, 1);
  --rpf-teal-100: hsla(176, 55%, 94%, 1);
  --rpf-teal-200: hsla(174, 55%, 85%, 1);
  --rpf-teal-400: hsla(174, 55%, 70%, 1);
  --rpf-teal-600: hsla(174, 56%, 56%, 1);
  --rpf-teal-800: hsla(174, 81%, 41%, 1);
  --rpf-teal-900: hsla(174, 81%, 27%, 1);
  --rpf-orange-100: hsla(31, 100%, 95%, 1);
  --rpf-orange-200: hsla(32, 100%, 88%, 1);
  --rpf-orange-400: hsla(31, 100%, 77%, 1);
  --rpf-orange-600: hsla(31, 99%, 65%, 1);
  --rpf-orange-800: hsla(31, 99%, 54%, 1);
  --rpf-orange-900: hsla(31, 85%, 35%, 1);
  --rpf-yellow-100: hsla(51, 100%, 95%, 1);
  --rpf-yellow-200: hsla(52, 100%, 87%, 1);
  --rpf-yellow-400: hsla(51, 100%, 75%, 1);
  --rpf-yellow-600: hsla(52, 100%, 63%, 1);
  --rpf-yellow-800: hsla(52, 100%, 50%, 1);
  --rpf-yellow-900: hsla(52, 100%, 26%, 1);
  --rpf-purple-100: hsla(282, 36%, 95%, 1);
  --rpf-purple-200: hsla(279, 38%, 87%, 1);
  --rpf-purple-400: hsla(279, 38%, 73%, 1);
  --rpf-purple-600: hsla(279, 37%, 60%, 1);
  --rpf-purple-800: hsla(279, 43%, 47%, 1);
  --rpf-purple-900: hsla(280, 43%, 37%, 1);
  --rpf-navy-100: hsla(235, 38%, 94%, 1);
  --rpf-navy-200: hsla(234, 38%, 84%, 1);
  --rpf-navy-400: hsla(235, 38%, 69%, 1);
  --rpf-navy-600: hsla(235, 38%, 53%, 1);
  --rpf-navy-800: hsla(235, 63%, 37%, 1);
  --rpf-navy-900: hsla(234, 63%, 30%, 1);
  --rpf-blue-100: hsla(203, 86%, 95%, 1);
  --rpf-blue-200: hsla(204, 88%, 87%, 1);
  --rpf-blue-400: hsla(204, 88%, 74%, 1);
  --rpf-blue-600: hsla(204, 89%, 60%, 1);
  --rpf-blue-800: hsla(204, 100%, 47%, 1);
  --rpf-blue-900: hsla(204, 99%, 37%, 1);
}

:root, :host {
  --rpf-white: hsla(0, 0%, 100%, 1);
  --rpf-off-white: hsla(225, 25%, 97%, 1);
  --rpf-grey-50: hsla(210, 8%, 95%, 1);
  --rpf-grey-100: hsla(228, 10%, 90%, 1);
  --rpf-grey-150: hsla(223, 9%, 85%, 1);
  --rpf-grey-200: hsla(228, 10%, 80%, 1);
  --rpf-grey-300: hsla(229, 9%, 75%, 1);
  --rpf-grey-400: hsla(229, 8%, 61%, 1);
  --rpf-grey-500: hsla(229, 9%, 42%, 1);
  --rpf-grey-600: hsla(228, 9%, 32%, 1);
  --rpf-grey-700: hsla(228, 9%, 22%, 1);
  --rpf-grey-800: hsla(232, 9%, 18%, 1);
  --rpf-grey-850: hsla(228, 9%, 15%, 1);
  --rpf-grey-900: hsla(228, 9%, 10%, 1);
  --rpf-grey-950: hsla(228, 8%, 5%, 1);
  --rpf-black: hsla(0, 0%, 0%, 1);
}

body {
  --cc-green: hsla(129, 47%, 48%, 1);
  --cc-blue: hsla(238, 39%, 45%, 1);
  --cc-raspberry: var(--rpf-brand-raspberry);
  --cc-yellow: hsla(53, 100%, 50%, 1);
  --cc-orange: hsla(13, 87%, 61%, 1);
  --cc-pink: hsla(332, 82%, 66%, 1);
  --cc-cyan: hsla(199, 74%, 54%, 1);
  --cc-off-white: hsla(36, 38%, 95%, 1);
  --cc-burgundy: hsla(332, 48%, 26%, 1);
  --cc-dark-green: hsla(166, 68%, 7%, 1);
  --cc-grey: hsla(237, 17%, 26%, 1);
  --cc-light-yellow: hsla(56, 88%, 78%, 1);
  --cc-light-orange: hsla(13, 89%, 89%, 1);
  --cc-light-pink: hsla(333, 100%, 91%, 1);
  --cc-light-cyan: hsla(199, 100%, 87%, 1);
  --cc-light-green: var(--cc-green-200);
  --cc-light-pink: hsla(333, 100%, 91%, 1);
  --cc-light-lilac: hsla(239, 87%, 88%, 1);
  --cc-green-700: hsla(129, 47%, 29%, 1);
  --cc-green-600: hsla(129, 47%, 39%, 1);
  --cc-green-500: var(--cc-green);
  --cc-green-400: hsla(129, 45%, 59%, 1);
  --cc-green-300: hsla(129, 44%, 69%, 1);
  --cc-green-200: hsla(129, 58%, 83%, 1);
  --cc-green-100: hsla(130, 46%, 95%, 1);
  --cc-green-50: hsla(130, 43%, 97%, 1);
  --cc-blue: #46499e;
  --cc-pale-orange: hsla(13, 100%, 97%, 1);
  --cc-pale-cyan: hsla(198, 91%, 96%, 1);
  --cc-pale-green: hsla(127, 100%, 96%, 1);
  --cc-pale-pink: hsla(332, 100%, 97%, 1);
  --cc-pale-lilac: hsla(240, 100%, 96%, 1);
  --rpf-white: #ffffff;
  --rpf-off-white: #f5f6f9;
  --rpf-black: #000000;
  --rpf-red: #c2423f;
  --ccw-blue: #2253e7;
  --legacy-grey-mid: #999999;
  --legacy-grey-light: #b8b8b8;
  --legacy-grey-x-light: #f5f6fa;
  --legacy-ui-grey-dark: hsl(0, 0%, 27%);
  --legacy-ui-yellow: hsl(57, 95%, 74%);
  --legacy-blue: hsl(194, 53%, 51%);
  --legacy-blue-light: hsl(194, 52%, 95%);
  --legacy-navy: hsl(207, 100%, 25%);
  --legacy-orange-light: hsl(25, 90%, 96%);
  --legacy-red: hsl(1, 87%, 63%);
  --legacy-yellow: hsl(38, 100%, 58%);
}

/**
 * The base value the scale starts at.
 *
 * Number
 */
/**
 * The ratio the scale is built on
 *
 * Unitless Number
 */
/**
 * Length of scale ( right part of the decimal
 * point ) ms will be rounded to.
 *
 * Unitless Number
 */
/* stylelint-disable */
/**
 * Based on a scale with a base of 1rem and a ratio of 1.2
 * https://www.modularscale.com/?1&rem&1.2
 * Each step up:   (previous-step) * 1.2
 * Each step down: (previous-step) / 1.2
 */
:root,
:host {
  --font-size-base: 1.6rem;
  --font-size-ratio: 1.2;
  --font-size-u-8: 6.879707136rem;
  --font-size-u-7: 5.73308928rem;
  --font-size-u-6: 4.7775744rem;
  --font-size-u-5: 3.981312rem;
  --font-size-u-4: 3.31776rem;
  --font-size-u-3: 2.7648rem;
  --font-size-u-2: 2.304rem;
  --font-size-u-1: 1.92rem;
  --font-size-d-1: 1.3333333333rem;
  --font-size-d-2: 1.1111111111rem;
  --font-size-d-3: 0.9259259259rem;
  --font-size-root-clamp-ideal: 3vmin;
  --font-size-root-clamp-min: 1em;
  --font-size-root-clamp-max: 1.25em;
  --font-size-root-clamp-fallback: 1em;
}

:root,
:host {
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
}

:root,
:host {
  --spacing-multiplier: 0.8;
  --spacing-0: 0;
  --spacing-1: calc(1rem * var(--spacing-multiplier));
  --spacing-2: calc(2rem * var(--spacing-multiplier));
  --spacing-3: calc(3rem * var(--spacing-multiplier));
  --spacing-4: calc(4rem * var(--spacing-multiplier));
  --spacing-5: calc(5rem * var(--spacing-multiplier));
  --spacing-6: calc(6rem * var(--spacing-multiplier));
  --spacing-7: calc(7rem * var(--spacing-multiplier));
  --spacing-8: calc(8rem * var(--spacing-multiplier));
  --spacing-9: calc(9rem * var(--spacing-multiplier));
  --spacing-10: calc(10rem * var(--spacing-multiplier));
}

.u-visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.shadow {
  align-items: center;
  background-color: #FF1493;
  display: flex;
  height: 50vh;
  justify-content: center;
  margin-bottom: 2rem;
  width: 50vw;
}

.rpf-shadow-bottom {
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.4);
}

.rpf-shadow-centre {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}

.rpf-shadow-top {
  box-shadow: 0px -12px 24px 0px rgba(0, 0, 0, 0.04);
}

:root,
:host {
  --rpi-transparent: rgba(0, 0, 0, 0);
  --rpi-white: #fff;
  --rpi-black: #000;
  --rpi-red: #f3524f;
  --rpi-red-shade-20: #c2423f;
  --rpi-red-tint-25: #f67d7b;
  --rpi-red-tint-50: #f9a9a7;
  --rpi-red-tint-75: #fcd4d3;
  --rpi-red-tint-90: #feeeed;
  --rpi-blue: #008ff0;
  --rpi-blue-shade-20: #0072c0;
  --rpi-blue-tint-25: #40abf4;
  --rpi-blue-tint-50: #80c7f8;
  --rpi-blue-tint-75: #bfe3fb;
  --rpi-blue-tint-90: #e6f4fe;
  --rpi-navy: #232e9b;
  --rpi-navy-shade-20: #1c257c;
  --rpi-navy-tint-25: #5a62b4;
  --rpi-navy-tint-50: #9197cd;
  --rpi-navy-tint-75: #c8cbe6;
  --rpi-navy-tint-90: #e9eaf5;
  --rpi-orange: #fe8e15;
  --rpi-orange-shade-20: #af620e;
  --rpi-orange-tint-25: #feaa50;
  --rpi-orange-tint-50: #ffc78a;
  --rpi-orange-tint-75: #ffe3c5;
  --rpi-orange-tint-90: #fff4e8;
  --rpi-yellow: #ffdc00;
  --rpi-yellow-shade-20: #877500;
  --rpi-yellow-tint-25: #ffe540;
  --rpi-yellow-tint-50: #ffee80;
  --rpi-yellow-tint-75: #fff6bf;
  --rpi-yellow-tint-90: #fffce6;
  --rpi-purple: #8744aa;
  --rpi-purple-shade-20: #6c3688;
  --rpi-purple-tint-25: #a573bf;
  --rpi-purple-tint-50: #c3a2d5;
  --rpi-purple-tint-75: #e1d0ea;
  --rpi-purple-tint-90: #f3ecf7;
  --rpi-green: #46af4b;
  --rpi-green-shade-20: #358539;
  --rpi-green-tint-25: #74c378;
  --rpi-green-tint-50: #a3d7a5;
  --rpi-green-tint-75: #d1ebd2;
  --rpi-green-tint-90: #edf7ed;
  --rpi-teal: #14bcac;
  --rpi-teal-shade-20: #0e857a;
  --rpi-teal-tint-25: #4fcdc1;
  --rpi-teal-tint-50: #8aded6;
  --rpi-teal-tint-75: #c4eeea;
  --rpi-teal-tint-90: #e8f8f7;
  --rpi-black: #000;
  --rpi-white: #fff;
  --rpi-grey-5: #f2f2f2;
  --rpi-grey-15: #d9d9d9;
  --rpi-grey-40: #999999;
  --rpi-grey-70: #4d4d4d;
  --rpi-off-white: #f5f6f9;
  --rpi-text: #212121;
  --rpi-black-alpha-10: $rpi-black-alpha-10;
  --rpi-black-alpha-15: $rpi-black-alpha-15;
  --rpi-black-alpha-25: $rpi-black-alpha-25;
  --rpi-black-alpha-30: $rpi-black-alpha-30;
  --rpi-black-alpha-40: $rpi-black-alpha-40;
  --rpi-brand-raspberry: #cd2355;
  --rpi-brand-leaf: #46af4b;
  --rpi-brand-black: #1d1d1b;
}

/* stylelint-disable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:export {
  red-rpi-red: #f3524f;
  red-rpi-red-shade-20: #c2423f;
  red-rpi-red-tint-25: #f67d7b;
  red-rpi-red-tint-50: #f9a9a7;
  red-rpi-red-tint-75: #fcd4d3;
  red-rpi-red-tint-90: #feeeed;
  blue-rpi-blue: #008ff0;
  blue-rpi-blue-shade-20: #0072c0;
  blue-rpi-blue-tint-25: #40abf4;
  blue-rpi-blue-tint-50: #80c7f8;
  blue-rpi-blue-tint-75: #bfe3fb;
  blue-rpi-blue-tint-90: #e6f4fe;
  navy-rpi-navy: #232e9b;
  navy-rpi-navy-shade-20: #1c257c;
  navy-rpi-navy-tint-25: #5a62b4;
  navy-rpi-navy-tint-50: #9197cd;
  navy-rpi-navy-tint-75: #c8cbe6;
  navy-rpi-navy-tint-90: #e9eaf5;
  orange-rpi-orange: #fe8e15;
  orange-rpi-orange-shade-20: #af620e;
  orange-rpi-orange-tint-25: #feaa50;
  orange-rpi-orange-tint-50: #ffc78a;
  orange-rpi-orange-tint-75: #ffe3c5;
  orange-rpi-orange-tint-90: #fff4e8;
  yellow-rpi-yellow: #ffdc00;
  yellow-rpi-yellow-shade-20: #877500;
  yellow-rpi-yellow-tint-25: #ffe540;
  yellow-rpi-yellow-tint-50: #ffee80;
  yellow-rpi-yellow-tint-75: #fff6bf;
  yellow-rpi-yellow-tint-90: #fffce6;
  purple-rpi-purple: #8744aa;
  purple-rpi-purple-shade-20: #6c3688;
  purple-rpi-purple-tint-25: #a573bf;
  purple-rpi-purple-tint-50: #c3a2d5;
  purple-rpi-purple-tint-75: #e1d0ea;
  purple-rpi-purple-tint-90: #f3ecf7;
  violet-rpi-violet: #8744aa;
  violet-rpi-violet-shade-20: #6c3688;
  violet-rpi-violet-tint-25: #a573bf;
  violet-rpi-violet-tint-50: #c3a2d5;
  violet-rpi-violet-tint-75: #e1d0ea;
  violet-rpi-violet-tint-90: #f3ecf7;
  green-rpi-green: #46af4b;
  green-rpi-green-shade-20: #358539;
  green-rpi-green-tint-25: #74c378;
  green-rpi-green-tint-50: #a3d7a5;
  green-rpi-green-tint-75: #d1ebd2;
  green-rpi-green-tint-90: #edf7ed;
  teal-rpi-teal: #14bcac;
  teal-rpi-teal-shade-20: #0e857a;
  teal-rpi-teal-tint-25: #4fcdc1;
  teal-rpi-teal-tint-50: #8aded6;
  teal-rpi-teal-tint-75: #c4eeea;
  teal-rpi-teal-tint-90: #e8f8f7;
  grey-rpi-transparent: rgba(0, 0, 0, 0);
  grey-rpi-black: #000;
  grey-rpi-grey-15: #d9d9d9;
  grey-rpi-grey-40: #999999;
  grey-rpi-grey-5: #f2f2f2;
  grey-rpi-grey-70: #4d4d4d;
  grey-rpi-off-white: #f5f6f9;
  grey-rpi-text: #212121;
  grey-rpi-white: #fff;
  grey-rpi-black-alpha-10: rgba(0, 0, 0, 0.1);
  grey-rpi-black-alpha-15: rgba(0, 0, 0, 0.15);
  grey-rpi-black-alpha-25: rgba(0, 0, 0, 0.25);
  grey-rpi-black-alpha-30: rgba(0, 0, 0, 0.3);
  grey-rpi-black-alpha-40: rgba(0, 0, 0, 0.4);
  brand-rpi-brand-black: #1d1d1b;
  brand-rpi-brand-leaf: #46af4b;
  brand-rpi-brand-raspberry: #cd2355;
}

/* stylelint-enable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:root,
:host {
  --rpi-transparent: rgba(0, 0, 0, 0);
  --rpi-white: #fff;
  --rpi-black: #000;
  --rpi-red: #f3524f;
  --rpi-red-shade-20: #c2423f;
  --rpi-red-tint-25: #f67d7b;
  --rpi-red-tint-50: #f9a9a7;
  --rpi-red-tint-75: #fcd4d3;
  --rpi-red-tint-90: #feeeed;
  --rpi-blue: #008ff0;
  --rpi-blue-shade-20: #0072c0;
  --rpi-blue-tint-25: #40abf4;
  --rpi-blue-tint-50: #80c7f8;
  --rpi-blue-tint-75: #bfe3fb;
  --rpi-blue-tint-90: #e6f4fe;
  --rpi-navy: #232e9b;
  --rpi-navy-shade-20: #1c257c;
  --rpi-navy-tint-25: #5a62b4;
  --rpi-navy-tint-50: #9197cd;
  --rpi-navy-tint-75: #c8cbe6;
  --rpi-navy-tint-90: #e9eaf5;
  --rpi-orange: #fe8e15;
  --rpi-orange-shade-20: #af620e;
  --rpi-orange-tint-25: #feaa50;
  --rpi-orange-tint-50: #ffc78a;
  --rpi-orange-tint-75: #ffe3c5;
  --rpi-orange-tint-90: #fff4e8;
  --rpi-yellow: #ffdc00;
  --rpi-yellow-shade-20: #877500;
  --rpi-yellow-tint-25: #ffe540;
  --rpi-yellow-tint-50: #ffee80;
  --rpi-yellow-tint-75: #fff6bf;
  --rpi-yellow-tint-90: #fffce6;
  --rpi-purple: #8744aa;
  --rpi-purple-shade-20: #6c3688;
  --rpi-purple-tint-25: #a573bf;
  --rpi-purple-tint-50: #c3a2d5;
  --rpi-purple-tint-75: #e1d0ea;
  --rpi-purple-tint-90: #f3ecf7;
  --rpi-green: #46af4b;
  --rpi-green-shade-20: #358539;
  --rpi-green-tint-25: #74c378;
  --rpi-green-tint-50: #a3d7a5;
  --rpi-green-tint-75: #d1ebd2;
  --rpi-green-tint-90: #edf7ed;
  --rpi-teal: #14bcac;
  --rpi-teal-shade-20: #0e857a;
  --rpi-teal-tint-25: #4fcdc1;
  --rpi-teal-tint-50: #8aded6;
  --rpi-teal-tint-75: #c4eeea;
  --rpi-teal-tint-90: #e8f8f7;
  --rpi-black: #000;
  --rpi-white: #fff;
  --rpi-grey-5: #f2f2f2;
  --rpi-grey-15: #d9d9d9;
  --rpi-grey-40: #999999;
  --rpi-grey-70: #4d4d4d;
  --rpi-off-white: #f5f6f9;
  --rpi-text: #212121;
  --rpi-black-alpha-10: $rpi-black-alpha-10;
  --rpi-black-alpha-15: $rpi-black-alpha-15;
  --rpi-black-alpha-25: $rpi-black-alpha-25;
  --rpi-black-alpha-30: $rpi-black-alpha-30;
  --rpi-black-alpha-40: $rpi-black-alpha-40;
  --rpi-brand-raspberry: #cd2355;
  --rpi-brand-leaf: #46af4b;
  --rpi-brand-black: #1d1d1b;
}

/* stylelint-disable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:export {
  red-rpi-red: #f3524f;
  red-rpi-red-shade-20: #c2423f;
  red-rpi-red-tint-25: #f67d7b;
  red-rpi-red-tint-50: #f9a9a7;
  red-rpi-red-tint-75: #fcd4d3;
  red-rpi-red-tint-90: #feeeed;
  blue-rpi-blue: #008ff0;
  blue-rpi-blue-shade-20: #0072c0;
  blue-rpi-blue-tint-25: #40abf4;
  blue-rpi-blue-tint-50: #80c7f8;
  blue-rpi-blue-tint-75: #bfe3fb;
  blue-rpi-blue-tint-90: #e6f4fe;
  navy-rpi-navy: #232e9b;
  navy-rpi-navy-shade-20: #1c257c;
  navy-rpi-navy-tint-25: #5a62b4;
  navy-rpi-navy-tint-50: #9197cd;
  navy-rpi-navy-tint-75: #c8cbe6;
  navy-rpi-navy-tint-90: #e9eaf5;
  orange-rpi-orange: #fe8e15;
  orange-rpi-orange-shade-20: #af620e;
  orange-rpi-orange-tint-25: #feaa50;
  orange-rpi-orange-tint-50: #ffc78a;
  orange-rpi-orange-tint-75: #ffe3c5;
  orange-rpi-orange-tint-90: #fff4e8;
  yellow-rpi-yellow: #ffdc00;
  yellow-rpi-yellow-shade-20: #877500;
  yellow-rpi-yellow-tint-25: #ffe540;
  yellow-rpi-yellow-tint-50: #ffee80;
  yellow-rpi-yellow-tint-75: #fff6bf;
  yellow-rpi-yellow-tint-90: #fffce6;
  purple-rpi-purple: #8744aa;
  purple-rpi-purple-shade-20: #6c3688;
  purple-rpi-purple-tint-25: #a573bf;
  purple-rpi-purple-tint-50: #c3a2d5;
  purple-rpi-purple-tint-75: #e1d0ea;
  purple-rpi-purple-tint-90: #f3ecf7;
  violet-rpi-violet: #8744aa;
  violet-rpi-violet-shade-20: #6c3688;
  violet-rpi-violet-tint-25: #a573bf;
  violet-rpi-violet-tint-50: #c3a2d5;
  violet-rpi-violet-tint-75: #e1d0ea;
  violet-rpi-violet-tint-90: #f3ecf7;
  green-rpi-green: #46af4b;
  green-rpi-green-shade-20: #358539;
  green-rpi-green-tint-25: #74c378;
  green-rpi-green-tint-50: #a3d7a5;
  green-rpi-green-tint-75: #d1ebd2;
  green-rpi-green-tint-90: #edf7ed;
  teal-rpi-teal: #14bcac;
  teal-rpi-teal-shade-20: #0e857a;
  teal-rpi-teal-tint-25: #4fcdc1;
  teal-rpi-teal-tint-50: #8aded6;
  teal-rpi-teal-tint-75: #c4eeea;
  teal-rpi-teal-tint-90: #e8f8f7;
  grey-rpi-transparent: rgba(0, 0, 0, 0);
  grey-rpi-black: #000;
  grey-rpi-grey-15: #d9d9d9;
  grey-rpi-grey-40: #999999;
  grey-rpi-grey-5: #f2f2f2;
  grey-rpi-grey-70: #4d4d4d;
  grey-rpi-off-white: #f5f6f9;
  grey-rpi-text: #212121;
  grey-rpi-white: #fff;
  grey-rpi-black-alpha-10: rgba(0, 0, 0, 0.1);
  grey-rpi-black-alpha-15: rgba(0, 0, 0, 0.15);
  grey-rpi-black-alpha-25: rgba(0, 0, 0, 0.25);
  grey-rpi-black-alpha-30: rgba(0, 0, 0, 0.3);
  grey-rpi-black-alpha-40: rgba(0, 0, 0, 0.4);
  brand-rpi-brand-black: #1d1d1b;
  brand-rpi-brand-leaf: #46af4b;
  brand-rpi-brand-raspberry: #cd2355;
}

/* stylelint-enable selector-pseudo-class-no-unknown, order/properties-alphabetical-order */
:root,
:host {
  --theme-100: var(--rpf-teal-100);
  --theme-200: var(--rpf-teal-200);
  --theme-300: var(--rpf-teal-800);
  --theme-400: var(--rpf-teal-900);
  --rpf-squiggle-color: var(--theme-300);
}

.c-site-header {
  background-color: var(--rpf-white);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  color: #212121;
  font-size: var(--font-size-u-1);
  position: relative;
  z-index: 1000;
}
@media screen and (min-width: 1000px) {
  .c-site-header {
    font-size: var(--font-size-u-1);
  }
}
@media screen and (min-width: 1200px) {
  .c-site-header {
    font-size: var(--font-size-base);
  }
}
@media screen and (min-width: 1600px) {
  .c-site-header {
    font-size: var(--font-size-u-1);
  }
}

.c-site-header__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
}
@media screen and (min-width: 1200px) {
  .c-site-header__container {
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 1.6rem;
  }
}

.c-site-header__rpf-link {
  display: inline-block;
}

.c-site-header__rpf-logomark {
  height: 4.4rem;
  width: auto;
}
@media screen and (min-width: 576px) {
  .c-site-header__rpf-logomark {
    height: 5.8rem;
  }
}

.c-site-header__home-link,
.c-site-header__home-link:visited {
  color: #212121;
  flex-shrink: 0;
  font-weight: 500;
  padding-inline: 0.8rem 2.4rem;
  text-decoration: none;
  white-space: nowrap;
}
@media screen and (min-width: 1200px) {
  .c-site-header__home-link,
  .c-site-header__home-link:visited {
    padding-inline: 1.6rem 4.8rem;
  }
}

.c-site-header__nav-link,
.c-site-header__nav-link:visited {
  align-items: center;
  color: #212121;
  display: flex;
  font-weight: 500;
  height: 4.8rem;
  padding: 0.8rem 1.6rem;
  text-decoration: none;
}

.c-site-header__nav-link:hover,
.c-site-header__home-link:hover {
  color: var(--legacy-navy);
}

.c-site-header__nav-link--button {
  background-color: var(--rpi-off-white);
  border-radius: 8px;
}

.c-site-header__language-selector--language {
  align-self: center;
  display: none;
  font-weight: 500;
  margin-right: 0;
  padding-inline-end: 1.6rem;
  text-decoration: none;
}
@media screen and (min-width: 576px) {
  .c-site-header__language-selector--language {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .c-site-header__language-selector--language {
    padding-inline: 2.4rem 1.6rem;
  }
}

.c-site-header__language-selector {
  align-items: center;
  border-inline-start: none;
  display: flex;
  margin-inline-end: 0.8rem;
}
@media screen and (min-width: 1200px) {
  .c-site-header__language-selector {
    border-inline-start: 1px solid var(--legacy-grey-light);
    margin-inline-end: 1.6rem;
  }
}

.c-site-header__nav-right {
  display: inline-block;
  margin-left: auto;
}
@media screen and (min-width: 1200px) {
  .c-site-header__nav-right {
    display: flex;
  }
}

.c-site-header__skiptocontent-link,
.c-site-header__skiptocontent-link:visited {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  color: #212121;
  display: block;
  font-weight: 500;
  height: 1px !important;
  margin: 0 !important;
  overflow: hidden !important;
  padding: 0 1.6rem;
  position: absolute !important;
  text-decoration: none;
  white-space: nowrap !important;
  width: 1px !important;
}

.c-site-header__skiptocontent-link:focus {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: inherit !important;
  overflow: visible !important;
  position: static !important;
  white-space: inherit !important;
  width: auto !important;
}

.c-site-header__toggle {
  display: flex;
  justify-content: right;
  order: 100;
}
@media screen and (min-width: 1200px) {
  .c-site-header__toggle {
    display: none;
  }
}
.c-site-header__toggle-button {
  appearance: none;
  background-color: #212121;
  border: none;
  height: 4.8rem;
  mask-image: url("./images/menu-cross.svg");
  mask-position: 0px 0px;
  mask-size: 4.8rem;
  transition-duration: 0.1s;
  transition-property: background-color;
  width: 4.8rem;
}
.c-site-header__toggle-button[aria-pressed=true] {
  background-color: var(--legacy-red);
  mask-position: 0px 100%;
}

.site-header__nav {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50px;
  transform: translateX(-100%);
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: 2s;
  width: 100%;
  z-index: 10000;
}
[data-nav-open=true] .site-header__nav {
  opacity: 1;
  transform: translateX(0);
}
@media screen and (min-width: 1200px) {
  .site-header__nav {
    align-self: inherit;
    flex-grow: 1;
    opacity: 1;
    position: static;
    transform: unset;
    transition-property: none;
    width: auto;
  }
}

.site-header-nav {
  background-color: var(--rpf-white);
  margin: 0;
}

.c-site-header-nav__list {
  margin: 0;
  margin: 1.6rem 2.4rem;
  padding-block-start: 1.6rem;
  padding-bottom: 2.4rem;
}
@media screen and (min-width: 1200px) {
  .c-site-header-nav__list {
    align-items: center;
    display: flex;
    gap: 0.8rem;
    justify-content: left;
    line-height: unset;
    margin: 0;
    padding: 0;
    padding-inline-end: 0.8rem;
  }
}

.c-site-header-nav__item {
  align-self: center;
  display: flex;
  opacity: 1;
  transition-duration: 0.15s;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  white-space: nowrap;
}
@media screen and (min-width: 1200px) {
  .c-site-header-nav__item {
    list-style: none;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  .c-site-header-nav__item:nth-child(3) {
    flex-grow: 1;
  }
}

.c-site-header__nested-links {
  display: none;
  margin-inline-start: 2.4rem;
}

.c-i18n-root--rtl .c-site-header-nav__list {
  justify-content: revert;
}

/* The dropdown container */
.c-site-header__dropdown {
  background-color: none;
  border-radius: 0.8rem;
  cursor: pointer;
  float: left;
  font-weight: 500;
  overflow: hidden;
  user-select: none;
}

.c-site-header__toggle-trigger-icon {
  appearance: none;
  display: inline-block;
  transition: all 150ms ease-in-out;
  vertical-align: middle;
}

/* Dropdown button */
.c-site-header__dropdown .c-site-header__dropdown-button {
  background-color: inherit;
  border: none;
  line-height: unset;
  margin: 0;
  outline: none;
}

/* Dropdown content (hidden by default) */
.c-site-header__dropdown-content {
  background-color: var(--rpf-white);
  border-radius: 0.8rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: none;
  margin-top: 0.8rem;
  padding: 0.8rem;
  position: absolute;
  z-index: 1;
}

/* Links inside the dropdown */
.c-site-header__dropdown-content a {
  color: black;
  display: block;
  float: none;
  padding: 1.2rem 1.6rem;
  text-align: left;
  text-decoration: none;
}

.c-site-header__dropdown-background {
  align-items: center;
  background-color: none;
  display: flex;
  gap: 0.8rem;
  height: 4.8rem;
  padding: 1.2rem 1.6rem;
}
@media screen and (min-width: 1200px) {
  .c-site-header__dropdown-background {
    background-color: none;
    border-radius: 0.8rem;
  }
}

.c-site-header__dropdown-background:hover {
  color: var(--legacy-navy);
}
.c-site-header__dropdown-background:hover .c-site-header__toggle-trigger-icon path {
  fill: var(--legacy-navy);
}

@media screen and (min-width: 1200px) {
  .c-site-header__dropdown-button:checked ~ .c-site-header__dropdown-content {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .c-site-header__dropdown-button:checked ~ .c-site-header__dropdown-background {
    background-color: var(--legacy-grey-x-light);
  }
}
.c-site-header__dropdown-button:checked ~ .c-site-header__dropdown-background .c-site-header__toggle-trigger-icon {
  transform: rotate(180deg);
}
.c-site-header__dropdown-button:checked ~ .c-site-header__nested-links {
  display: block;
}
@media screen and (min-width: 1200px) {
  .c-site-header__dropdown-button:checked ~ .c-site-header__nested-links {
    display: none;
  }
}
.c-site-header__dropdown-button:checked ~ .c-filter__dropdowns {
  display: block;
}

.c-site-header__mobile-nav-only {
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .c-site-header__mobile-nav-only {
    display: none;
  }
}

.c-site-desktop-nav-only {
  display: none;
}
@media screen and (min-width: 768px) {
  .c-site-desktop-nav-only {
    display: inline-block;
  }
}

