@import '@raspberrypifoundation/design-system-core/scss/properties/colors/_text.scss';
@import '@raspberrypifoundation/design-system-core/scss/properties/colors/_decorative.scss';
@import '@raspberrypifoundation/design-system-core/scss/properties/colors/_greys.scss';

body {
  // New Code Club colors/names;
  --cc-green: hsla(129, 47%, 48%, 1);
  --cc-blue: hsla(238, 39%, 45%, 1);
  --cc-raspberry: var(--rpf-brand-raspberry);
  --cc-yellow: hsla(53, 100%, 50%, 1);
  --cc-orange: hsla(13, 87%, 61%, 1);
  --cc-pink: hsla(332, 82%, 66%, 1);
  --cc-cyan: hsla(199, 74%, 54%, 1);
  --cc-off-white: hsla(36, 38%, 95%, 1);
  --cc-burgundy: hsla(332, 48%, 26%, 1);
  --cc-dark-green: hsla(166, 68%, 7%, 1);
  --cc-grey: hsla(237, 17%, 26%, 1);

  --cc-light-yellow: hsla(56, 88%, 78%, 1);
  --cc-light-orange: hsla(13, 89%, 89%, 1);
  --cc-light-pink: hsla(333, 100%, 91%, 1);
  --cc-light-cyan: hsla(199, 100%, 87%, 1);
  --cc-light-green: var(--cc-green-200);
  --cc-light-pink: hsla(333, 100%, 91%, 1);
  --cc-light-lilac: hsla(239, 87%, 88%, 1);

  --cc-green-700: hsla(129, 47%, 29%, 1);
  --cc-green-600: hsla(129, 47%, 39%, 1);
  --cc-green-500: var(--cc-green);
  --cc-green-400: hsla(129, 45%, 59%, 1);
  --cc-green-300: hsla(129, 44%, 69%, 1);
  --cc-green-200: hsla(129, 58%, 83%, 1);
  --cc-green-100: hsla(130, 46%, 95%, 1);
  --cc-green-50: hsla(130, 43%, 97%, 1);

  --cc-blue: #46499e;
  
  --cc-pale-orange: hsla(13, 100%, 97%, 1);
  --cc-pale-cyan: hsla(198, 91%, 96%, 1);
  --cc-pale-green: hsla(127, 100%, 96%, 1);
  --cc-pale-pink: hsla(332, 100%, 97%, 1);
  --cc-pale-lilac: hsla(240, 100%, 96%, 1);

  --rpf-white: #ffffff;
  --rpf-off-white: #f5f6f9;
  --rpf-black: #000000;
  --rpf-red: #c2423f;

  // legacy colors
  --ccw-blue: #2253e7;

  --legacy-grey-mid: #999999;
  --legacy-grey-light: #b8b8b8;
  --legacy-grey-x-light: #f5f6fa;
  --legacy-ui-grey-dark: hsl(0, 0%, 27%);

  --legacy-ui-yellow: hsl(57, 95%, 74%);
  --legacy-blue: hsl(194, 53%, 51%);
  --legacy-blue-light: hsl(194, 52%, 95%);
  --legacy-navy: hsl(207, 100%, 25%);
  --legacy-orange-light: hsl(25, 90%, 96%);
  --legacy-red: hsl(1, 87%, 63%);
  --legacy-yellow: hsl(38, 100%, 58%);
}

