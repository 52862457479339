/* stylelint-disable no-descending-specificity */
@use 'scss/properties/font-weight';
@use 'scss/mixins/viewport/index' as viewport;
@use '../../../assets/stylesheets/rpf_design_system/spacing' as *;
@import './../../../assets/stylesheets/component';

/*
  TODO
  - icons
*/
.button {
  appearance: auto;
  border: none;
  border-radius: 80px;
  box-sizing: border-box;
  color: var(--rpf-white);
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size-u-1);
  font-weight: var(--font-weight-bold);
  gap: 8px;
  min-block-size: 24px;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: fit-content;

  svg * {
    fill: var(--rpf-black);
  }

  &:hover:not(.disabled) {
    background-color: var(--rpf-grey-800);
  }

  &.active:not(.disabled),
  &:active:not(.disabled) {
    background-color: var(--rpf-grey-800);

    svg * {
      fill: var(--rpf-white);
    }
  }

  &.active:hover:not(.disabled),
  &:active:hover:not(.disabled) {
    background-color: var(--rpf-grey-800);
  }

  // https://www.w3.org/WAI/WCAG21/Understanding/focus-visible
  :focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible:not(.disabled),
  &:focus-visible:hover:not(.disabled) {
    outline: 2px solid var(--rpf-black);
    outline-offset: 3px;
  }
}

.button--primary {
  @extend .button;
  background-color: var(--rpf-black);

  svg * {
    fill: var(--rpf-white);
  }

  &:hover:not(.disabled),
  &.active:not(.disabled),
  &:active:not(.disabled),
  &.active:hover:not(.disabled),
  &:active:hover:not(.disabled) {
    background-color: var(--rpf-grey-800);
  }
}

.disabled {
  background-color: var(--rpi-grey-70);

  .icon path {
    fill: var(--rpf-white);
  }
}

.button--secondary {
  @extend .button;
  background-color: var(--rpf-white);
  border: 2px solid var(--rpf-black);
  color: var(--rpf-black);

  svg * {
    fill: var(--rpf-black);
  }

  &:hover:not(.disabled) {
    background-color: var(--rpf-grey-50);

    svg * {
      fill: var(--rpf-grey-800);
    }
  }

  &.active:not(.disabled),
  &:active:not(.disabled),
  &.active:hover:not(.disabled),
  &:active:hover:not(.disabled) {
    background-color: var(--rpf-white);
    color: var(--rpf-grey-700);

    svg * {
      fill: var(--rpf-grey-700);
    }
  }
}

.button--secondary.disabled {
  background-color: var(--rpf-white);
  color: var(--rpf-grey-300);

  svg * {
    fill: var(--rpf-grey-300);
  }
}

.button--tertiary {
  @extend .button;
  background-color: transparent;
  color: var(--rpf-black);

  svg * {
    fill: var(--rpf-black);
  }

  &:hover:not(.disabled) {
    background-color: transparent;
    color: var(--rpf-grey-800);

    svg * {
      fill: var(--rpf-grey-800);
    }
  }

  &.active:not(.disabled),
  &:active:not(.disabled),
  &.active:hover:not(.disabled),
  &:active:hover:not(.disabled) {
    background-color: transparent;
    color: var(--rpf-grey-700);

    svg * {
      fill: var(--rpf-grey-700);
    }
  }
}

.button--tertiary.disabled {
  background-color: transparent;
  color: var(--rpf-grey-800);
}

.content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .icon {
    align-items: center;
    display: flex;

    svg {
      width: var(--spacing-3);
    }
  }

  .icon_with_text {
    height: var(--spacing-3);
    margin-right: 0.5rem;
  }

  .icon_without_text {
    svg {
      height: var(--spacing-3);
    }
  }

  .icon_only {
    margin-right: 0;
  }
}

.content-with-icon--right {
  flex-direction: row-reverse;

  .icon_with_text {
    margin: 0 0 0 $space-0-5;
  }
}

.text_on_hover {
  .text {
    display: none;
  }

  &:hover {
    .text {
      display: inline;
    }

    .icon {
      display: none;
    }
  }
}

@include viewport.max-width(550px) {
  .with_icon {
    .text {
      display: none;
    }

    .icon {
      margin-right: 0;
    }
  }
}
/* stylelint-enable no-descending-specificity */
