@mixin rpi-breakpoint($size, $breakpoints: $iota-global-breakpoints) {
  $breakpoint: map-get($breakpoints, $size);

  @media #{$breakpoint} {
    @content;
  }
}

@mixin rpi-breakpoints($breakpoints: $iota-global-breakpoints) {
  @each $screensize, $command in $breakpoints {
    @media #{$command} {
      @content($screensize);
    }
  }
};
