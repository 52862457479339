$rpi-font-weight-light: 300;
$rpi-font-weight-regular: 400;
$rpi-font-weight-medium: 500;
$rpi-font-weight-bold: 700;

$rpi-font-weight: (
  null: (null: 300, sm: 300, md: 300, lg: 300),
  h1: (null: 700, sm: 700, md: 700, lg: 700),
  h2: (null: 500, sm: 500, md: 500, lg: 500),
  h3: (null: 500, sm: 500, md: 500, lg: 500),
  h4: (null: 500, sm: 500, md: 500, lg: 500),
  h5: (null: 300, sm: 300, md: 300, lg: 300),
  h6: (null: 300, sm: 300, md: 300, lg: 300)
);
