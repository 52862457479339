$rpi-font-size-base: 16px;

$rpi-font-size: (
  null: (null: 16px, sm: 18px, md: 18px, lg: 18px),
  h1: (null: 20px, sm: 30px, md: 35px, lg: 35px),
  h2: (null: 20px, sm: 25px, md: 25px, lg: 25px),
  h3: (null: 17px, sm: 17px, md: 17px, lg: 17px),
  h4: (null: 19px, sm: 16px, md: 16px, lg: 16px),
  h5: (null: 16px, sm: 16px, md: 16px, lg: 16px),
  h6: (null: 16px, sm: 16px, md: 16px, lg: 16px)
);
