@import './../../../assets/stylesheets/component';

$rpi-color-error-screen-bg: #53ce63;

.c-error {
  background-color: var(--rpf-text-primary);
  min-height: calc(100vh - 40px);
  position: relative;
}

.c-error__wrapper {
  color: var(--rpf-white);
  left: 50%;
  max-width: 400px;
  min-width: 320px;
  padding: 0 rpi-spacing-unit(2);
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);

  @include rpi-breakpoint(sm) {
    margin: 0 auto;
    min-width: 630px;
    padding: 0;
    text-align: left;
  }

  @include rpi-breakpoint(md) {
    min-width: 680px;
  }
}

.c-error__image {
  background-image: url('./images/computer.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 170px 146px;
  display: block;
  height: 146px;
  margin-bottom: rpi-spacing-unit(6);
  position: relative;

  @include rpi-breakpoint(sm) {
    background-size: 210px 180px;
    float: left;
    height: 180px;
    margin: rpi-spacing-unit(2) 0 0;
    width: 210px;
  }

  @include rpi-breakpoint(md) {
    margin: rpi-spacing-unit(3) 0 0;
  }
}

.c-error__screen {
  background: $rpi-color-error-screen-bg;
  display: block;
  height: 75px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 16px;
  transform: translateX(-50%);
  width: 140px;

  @include rpi-breakpoint(sm) {
    height: 93px;
    top: 19px;
    width: 172px;
  }
}

.c-error__dialog {
  background-image: url('./images/dialog.svg');
  display: inline-block;
  height: 25px;
  position: absolute;
  width: 45px;
}

.c-error__body {
  @include rpi-breakpoint(sm) {
    float: right;
    width: 330px;
  }

  @include rpi-breakpoint(md) {
    width: 380px;
  }
}

.c-error__title {
  @include rpi-type(26px, 1.2);
  font-weight: $rpi-font-weight-medium;
  margin-bottom: rpi-spacing-unit(2);
}

.c-error__button {
  background-color: var(--legacy-ui-yellow);
  border-radius: 5px;
  color: var(--rpf-text-primary);
  font-weight: $rpi-font-weight-medium;
  padding: rpi-spacing-unit() rpi-spacing-unit(4);
  text-decoration: none;
  transition: all 150ms ease-in-out;

  &:visited {
    color: var(--rpf-text-primary);
  }

  &:hover {
    background-color: var(--rpf-white);
    color: var(--rpf-text-primary);
  }
}

.display-false {
  display: none;
}
