:root,
:host {
  --spacing-multiplier: 0.8;
  --spacing-0: 0;
  --spacing-1: calc(1rem * var(--spacing-multiplier)); // 0.8rem
  --spacing-2: calc(2rem * var(--spacing-multiplier)); // 1.6rem
  --spacing-3: calc(3rem * var(--spacing-multiplier)); // 2.4rem
  --spacing-4: calc(4rem * var(--spacing-multiplier)); // 3.2rem
  --spacing-5: calc(5rem * var(--spacing-multiplier)); // 4.0rem
  --spacing-6: calc(6rem * var(--spacing-multiplier)); // 4.8rem
  --spacing-7: calc(7rem * var(--spacing-multiplier)); // 5.6rem
  --spacing-8: calc(8rem * var(--spacing-multiplier)); // 6.4rem
  --spacing-9: calc(9rem * var(--spacing-multiplier)); // 7.2rem
  --spacing-10: calc(10rem * var(--spacing-multiplier)); // 8rem
}

